import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import './header.css'

import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Header = ({ siteTitle }) => {
  const [menuToggle, setMenuToggle] = useState(false)

  const handleToggle = () => setMenuToggle(!menuToggle)

  return (
    <header className='main-header row justify-content-between m-0'>
      <div className='site-title m-0 p-0'>
        <Link to='/'>
          <img
            className='site-logo'
            src='https://roderick-cardenas-portfolio.s3.eu-west-2.amazonaws.com/logo-white-shark.svg'
            alt=''
          />
        </Link>
      </div>
      <button
        style={{ outline: 'none' }}
        onClick={handleToggle}
        className='mobile-menu'
      >
        <img
          src='https://roderick-cardenas-portfolio.s3.eu-west-2.amazonaws.com/burger.svg'
          alt=''
        />
      </button>

      <ul className='main-nav m-0 p-0'>
        <li className='about-link'
          style={{
            display: 'inline',
            marginRight: '1rem'
          }}
        >
          <AnchorLink to='/#about' className='stripped' stripHash>
            About
          </AnchorLink>
        </li>
        <li className='projects-link'
          style={{
            display: 'inline',
            marginRight: '1rem'
          }}
        >
          <AnchorLink to='/#projects' className='stripped' stripHash>
            Projects
          </AnchorLink>
        </li>
        <li className='contact-link'
          style={{
            display: 'inline',
            marginRight: '1rem'
          }}
        >
          <AnchorLink to='/#contact' className='stripped' stripHash>
            Contact
          </AnchorLink>
        </li>
      </ul>

      {/* Mobile Nav*/}
      <div
        style={{ display: menuToggle ? 'block' : 'none' }}
        className='mobile-nav'
      >
        <div>
          <button
            onClick={handleToggle}
            style={{ outline: 'none' }}
            className='close-mobile'
          >
            <h1>X</h1>
          </button>
          <ul>
            <li>
              <AnchorLink to='/#about' className='stripped' stripHash>
                <button onClick={handleToggle} className='link-button'>
                  About
                </button>
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to='/#projects' className='stripped' stripHash>
                <button onClick={handleToggle} className='link-button'>
                  Projects
                </button>
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to='/#contact' className='stripped' stripHash>
                <button onClick={handleToggle} className='link-button'>
                  Contact
                </button>
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
